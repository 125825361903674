export interface IPayableBankAccountModel {
	payableBankAccountId: number;
	description: string;
	active: boolean;
}

export class PayableBankAccountModel implements IPayableBankAccountModel {
	payableBankAccountId: number;
	description: string;
	active: boolean;
}