import { IRoomDesignModel } from '../room-design/room-design.model';
import { IMitigationAtmosphericReadingModel } from './mitigation-atmospheric-reading.model';
import { IMitigationChecklistValueModel } from './mitigation-checklist-value.model';
import { IMitigationRoomStructureModel } from './mitigation-room-structure.model';

export interface IMitigationRoomModel {
	mitigationRoomId: number;
	mitigationPlanId: number;
	jobName: string;
	roomName: string;
	category?: number;
	class?: number
	roomSqft?: number;
	roomCuft?: number;
	documentsFolderUuid: string;
	photosFolderUuid: string;

	mitigationChecklistValues: IMitigationChecklistValueModel[];
	roomDesign: IRoomDesignModel;
	atmosphericReadings: IMitigationAtmosphericReadingModel[];
	roomStructures: IMitigationRoomStructureModel[];
}

export class MitigationRoomModel implements IMitigationRoomModel {
	mitigationRoomId: number;
	mitigationPlanId: number;
	jobName: string;
	roomName: string;
	category?: number;
	class?: number
	roomSqft?: number;
	roomCuft?: number;
	documentsFolderUuid: string;
	photosFolderUuid: string;

	mitigationChecklistValues: IMitigationChecklistValueModel[];
	roomDesign: IRoomDesignModel;
	atmosphericReadings: IMitigationAtmosphericReadingModel[];
	roomStructures: IMitigationRoomStructureModel[];
}

