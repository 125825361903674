export interface IRegionModel {
    regionId: number;
    regionName: string;
    active: boolean;

}

export class RegionModel implements IRegionModel {
    regionId: number;
    regionName: string;
    active: boolean;
}
