import { Options } from "@angular-slider/ngx-slider";

export interface IMitigationChecklistDefinitionModel {
	mitigationChecklistDefinitionId: number;
	uuid: string;
	sortOrder: number;
	checklistType: string;
	groupKey: string;
	label: string;
	conditionLabel: string;
	checklistValues: string;
	showOnRollupReport: boolean;
	isDeleted: boolean;
}

export class MitigationChecklistDefinitionModel implements IMitigationChecklistDefinitionModel {
	mitigationChecklistDefinitionId: number;
	uuid: string;
	sortOrder: number;
	checklistType: string;
	groupKey: string;
	label: string;
	conditionLabel: string;
	checklistValues: string;
	showOnRollupReport: boolean;
	isDeleted: boolean;
}