import { IContactModel } from "@models/contacts/contact.model";

export interface IVendorModel {
	vendorId: number;
	uuid: string;
	version: number;
	active: boolean;

	// This is only used for QB vendor sync
	isSubcontractor: boolean;

	contact: IContactModel;
}

export class VendorModel implements IVendorModel {
	vendorId: number;
	uuid: string;
	version: number;
	active: boolean;

	// This is only used for QB vendor sync
	isSubcontractor: boolean;

	contact: IContactModel;
}
