import { IAddressModel } from "./address.model";
import { IEmailAddressModel } from "./email-address.model";
import { IPhoneNumberModel } from "./phone-number.model";

export interface IContactModel {
	contactId: number;
	contactType: string;
	firstName: string;
	lastName: string;
	fullName: string;
	companyName: string;
	website: string;
	displayName: string;
	defaultAddress: IAddressModel;
	defaultHome: IPhoneNumberModel;
	defaultWork: IPhoneNumberModel;
	defaultCell: IPhoneNumberModel;
	defaultAlt1: IPhoneNumberModel;
	defaultAlt2: IPhoneNumberModel;
	defaultEmailAddress: IEmailAddressModel;
	secondaryEmailAddress: IEmailAddressModel;
	quickbooksName: string;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	addresses: IAddressModel[];
	phoneNumbers: IPhoneNumberModel[];
	emailAddresses: IEmailAddressModel[];
}

export class ContactModel implements IContactModel {
	contactId: number;
	contactType: string;
	firstName: string;
	lastName: string;
	fullName: string;
	companyName: string;
	website: string;
	displayName: string;
	defaultAddress: IAddressModel;
	defaultHome: IPhoneNumberModel;
	defaultWork: IPhoneNumberModel;
	defaultCell: IPhoneNumberModel;
	defaultAlt1: IPhoneNumberModel;
	defaultAlt2: IPhoneNumberModel;
	defaultEmailAddress: IEmailAddressModel;
	secondaryEmailAddress: IEmailAddressModel;
	quickbooksName: string;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	addresses: IAddressModel[];
	phoneNumbers: IPhoneNumberModel[];
	emailAddresses: IEmailAddressModel[];

	constructor() {
		this.contactId = 0;
		this.addresses = [];
		this.phoneNumbers = [];
		this.emailAddresses = [];
	}
}
