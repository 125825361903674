export interface IMitigationChecklistValueModel {
	mitigationChecklistValueId: number;
	mitigationRoomId: number;
	checklistChecked: boolean;
	checklistValue: string;
	groupKey: string;
	sortOrder: number;
	checklistType: string;
	label: string;
	conditionLabel: string;
	checklistValues: string;
	showOnRollupReport: boolean;

	isVisible: boolean;
	ngxSliderValueIdx: number;
	ngxSliderOptions: any;
}

export class MitigationChecklistValueModel implements IMitigationChecklistValueModel {
	mitigationChecklistValueId: number;
	mitigationRoomId: number;
	checklistChecked: boolean;
	checklistValue: string;
	groupKey: string;
	sortOrder: number;
	checklistType: string;
	label: string;
	conditionLabel: string;
	checklistValues: string;
	showOnRollupReport: boolean;

	isVisible: boolean;
	ngxSliderValueIdx: number;
	ngxSliderOptions: any;
}