
export interface IMitigationReportModel {
    mitigationReportId: number;
    createdDate: Date;
    createdBy: string;
    companyId: number;
    jobId: number;
    reportName: string;
    filePath: string;
    fileName: string;
}

export class MitigationReportModel implements IMitigationReportModel {

    mitigationReportId: number;
    createdDate: Date;
    createdBy: string;
    companyId: number;
    jobId: number;
    reportName: string;
    filePath: string;
    fileName: string;

    constructor(data?: IMitigationReportModel) {
        if (data) {
            this.mitigationReportId = data.mitigationReportId;
            this.createdDate = new Date(data.createdDate);
            this.createdBy = data.createdBy;
            this.companyId = data.companyId;
            this.jobId = data.jobId;
            this.reportName = data.reportName;
            this.filePath = data.filePath;
        }
    }
}
