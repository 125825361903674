export interface ICOGSItemModel {
	cogsItemId: number;
	qbwcClassId?: number;
	itemCode: string;
	description: string;
	uom: string;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksItemType: string;
	quickbooksIncomeAccountId: string;
	quickbooksExpenseAccountId: string;
	active: boolean;
}

export class COGSItemModel implements ICOGSItemModel {
	cogsItemId: number;
	qbwcClassId?: number;
	itemCode: string;
	description: string;
	uom: string;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	quickbooksCategoryId: string;
	quickbooksItemType: string;
	quickbooksIncomeAccountId: string;
	quickbooksExpenseAccountId: string;
	active: boolean;
}