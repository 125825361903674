import { Injectable } from "@angular/core";
import { IEmailerModel, IMitigationReportModel } from "@models";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { ISlickFileModel } from "slick-components";

@Injectable({
    providedIn: 'root' // This makes the service available application-wide
})
export class MitigationReportService {
    constructor(private httpService: HttpService) { }

    
    async getMitigationReportsForJob(jobId: number): Promise<IMitigationReportModel[]> {
        return this.httpService.get("/jobReports/getMitigationReportsForJob", { jobId: jobId });
    }

    
    async getMitigationReport(jobId: number, reportId: number): Promise<IMitigationReportModel> {
        return this.httpService.get("/jobReports/getMitigationReport", { jobId: jobId, reportId: reportId });
    }

   
    async generateMitigationReport(reportModel: IMitigationReportModel): Promise<IMitigationReportModel> {
        return this.httpService.post(`/jobReports/generateMitigationReport`, reportModel);
    }

    async deleteMitigationReport(reportModel: IMitigationReportModel): Promise<boolean> {
        try {
            await this.httpService.delete("/jobReports/deleteMitigationReport", {
                reportId: reportModel.mitigationReportId
                
            });
            return true;
        } catch (error) {
            console.error("Error deleting mitigation report:", error);
            return false;
        }
    }
    
    async getMitigationReportPDF(mitigationReportId): Promise<string> {
        const response = await this.httpService.get(`/jobReports/getMitigationReportPDF?mitigationReportId=${mitigationReportId}`);

        return response.url;
    }

    async sendMitigationReportEmail(mitigationReportId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {
        const formData: FormData = new FormData();
        emailerModel.attachments.forEach(file => {
            if (file.base64Image && file.base64Image.length > 0) {
                let fileCopy: ISlickFileModel = UtilsService.clone(file);
                let fileName = file.name;
                delete fileCopy.file;
                delete fileCopy.thumbnailBase64Image;
                let fileJSON = JSON.stringify(fileCopy);
                formData.append('image_' + file.uuid, fileJSON);
            }
            else if (file.file) {
                formData.append('file', file.file, file.file.name);
            }
        })

        let emailerModelTemp: IEmailerModel = UtilsService.clone(emailerModel);
        emailerModelTemp.attachments = emailerModelTemp.attachments.map(att => {
            delete att.file;
            delete att.base64Image;
            delete att.thumbnailBase64Image;
            return att;
        });
        formData.append("emailerModel", JSON.stringify(emailerModelTemp));

        return this.httpService.postMultipart(`/jobReports/sendMitigationReportEmail?mitigationReportId=${mitigationReportId}`, formData);
    }
}

    
