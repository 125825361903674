
<div class="d-flex flex-column" style="min-width: 800px">
    <div class="d-flex flex-fill mb-2" style="line-height: 26px;">
        <div><label>Quick Task:</label></div>
        <div class="flex-fill ms-1"><input type="text" class="form-control flex-fill" style="line-height: 26px; height: 26px;" [(ngModel)]="quickTaskText" (keydown)="onKeyDown($event)" /></div>

    </div>

    <table class="table table-sm table-striped table-borderless table-hover border" style="table-layout: fixed; min-width: 600px;">
        <colgroup>
            <col style="width: 22px" />
            <col />
            <col />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 100px" />
        </colgroup>
        <thead>
            <tr>
                <td></td>
                <td>Title</td>
                <td>Job Name</td>
                <td>Owner</td>
                <td>Assigned To</td>
                <td>Due Date</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let task of tasks" (click)="openTask(task)">
                <td style="text-align: center">
                    <div *ngIf="!task.isCompleted && !task.isCompleting">
                        <button class="btn btn-link text-body p-0 m-0" (click)="completeTask($event, task)"><i class="far fa-check-circle"></i></button>
                    </div>
                    <div *ngIf="task.isCompleting && !task.isCompleted">
                        <span class="waiting-gif"></span>
                    </div>
                    <div *ngIf="!task.isCompleting && task.isCompleted">
                        <i class="far fa-check-circle text-success"></i>
                    </div>
                </td>
                <td>{{task.title}}</td>
                <td>{{task.jobName}}</td>
                <td>{{task.ownerFullName}}</td>
                <td>{{task.assignedToUserFullNames}}</td>
                <td>{{task.dueDate | date:'M/d/yyyy'}}</td>
            </tr>
        </tbody>
    </table>
</div>

<task-edit-dialog #taskEditDialogRef></task-edit-dialog>