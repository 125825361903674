import { Injectable } from "@angular/core";

export class KeywordCategories {
	category: string;
	keywords: string[];
}


@Injectable()
export class TemplatesService {
	companyKeywords: KeywordCategories = { category: 'Company', keywords: ['%COMPANY_LOGO%', '%COMPANY_NAME%', '%COMPANY_FULL_ADDRESS%', '%COMPANY_CITY_STATE_POSTAL%', '%COMPANY_ADDRESS1%', '%COMPANY_ADDRESS2%', '%COMPANY_CITY%', '%COMPANY_STATE%', '%COMPANY_POSTAL_CODE%', '%COMPANY_WORK_PHONE%', '%COMPANY_CELL_PHONE%', '%COMPANY_ALT1_PHONE%', '%COMPANY_ALT2_PHONE%', '%COMPANY_EMAIL%', '%COMPANY_WEBSITE%'] };
	estimateKeywords: KeywordCategories = { category: 'Estimate', keywords: ['%ESTIMATE_NAME%', '%ESTIMATE_TOTAL%'] };
	customerKeywords: KeywordCategories = { category: 'Customer', keywords: ['%FIRST_NAME%', '%LAST_NAME%', '%FULL_NAME%', '%CUSTOMER_COMPANY_NAME%', '%CUSTOMER_DISPLAY_NAME%', '%FULL_ADDRESS%', '%ADDRESS1%', '%ADDRESS2%', '%CITY%', '%STATE%', '%POSTAL_CODE%', '%CELL_PHONE%', '%ALT1_PHONE%', '%ALT2_PHONE%', '%EMAIL%'] };
	invoiceKeywords: KeywordCategories = { category: 'Invoice', keywords: ['%INVOICE_NUMBER%', '%PO_NUMBER%', '%INVOICE_DUE_DATE%', '%INVOICE_TOTAL%', '%INVOICE_LINE_ITEMS%', '%CREDIT_LINE_ITEMS%', '%AMOUNT_DUE%', '%APPLIED_TOTAL%', '%INVOICE_PRINTOUT_NOTES%', '%ONLINE_PAYMENT_LINK%'] };
	paymentKeywords: KeywordCategories = { category: 'Payment', keywords: ['%PAYMENT_DATE%', '%PAYMENT_REF_NUMBER%', '%PAYMENT_TYPE%', '%PAYMENT_AMOUNT%', '%PAYMENT_REMAINING%', '%APPLIED_PAYMENTS%'] };
	jobKeywords: KeywordCategories = { category: 'Job', keywords: ['%JOB_NAME%', '%JOB_STATUS%', '%ESTIMATOR_NAME%', '%PRODUCTION_MANAGER%', '%SPECIALTY_MANAGER%', '%PROPERTY_TYPE%', '%DAMAGE_TYPE%', '%CLAIM_NUMBER%', '%DATE_OF_LOSS%', '%YEAR_BUILT%', '%PRIMARY_FIRST_NAME%', '%PRIMARY_LAST_NAME%', '%PRIMARY_FULL_NAME%', '%PRIMARY_COMPANY_NAME%', '%PRIMARY_DISPLAY_NAME%', '%PRIMARY_FULL_ADDRESS%', '%PRIMARY_ADDRESS1%', '%PRIMARY_ADDRESS2%', '%PRIMARY_CITY%', '%PRIMARY_STATE%', '%PRIMARY_POSTAL_CODE%', '%PRIMARY_CELL_PHONE%', '%PRIMARY_ALT1_PHONE%', '%PRIMARY_ALT2_PHONE%', '%PRIMARY_EMAIL%', '%BILLING_FIRST_NAME%', '%BILLING_LAST_NAME%', '%BILLING_FULL_NAME%', '%BILLING_COMPANY_NAME%', '%BILLING_DISPLAY_NAME%', '%BILLING_FULL_ADDRESS%', '%BILLING_ADDRESS1%', '%BILLING_ADDRESS2%', '%BILLING_CITY%', '%BILLING_STATE%', '%BILLING_POSTAL_CODE%', '%BILLING_CELL_PHONE%', '%BILLING_ALT1_PHONE%', '%BILLING_ALT2_PHONE%', '%BILLING_EMAIL%', '%CARRIER_NAME%', '%CARRIER_ADDRESS%', '%CARRIER_CELL_PHONE%', '%CARRIER_WORK_PHONE%', '%CARRIER_EMAIL%', '%ADJUSTER_NAME%', '%ADJUSTER_ADDRESS%', '%ADJUSTER_CELL_PHONE%', '%ADJUSTER_WORK_PHONE%', '%ADJUSTER_EMAIL%'] };
	workOrderKeywords: KeywordCategories = { category: 'Work Order', keywords: ['%WORK_ORDER_NUMBER%', '%SUBCONTRACTOR_FULL_NAME%', '%SUBCONTRACTOR_COMPANY_NAME%', '%SUBCONTRACTOR_DISPLAY_NAME%', '%SUBCONTRACTOR_FULL_ADDRESS%', '%SUBCONTRACTOR_CITY_STATE_POSTAL%', '%SUBCONTRACTOR_ADDRESS1%', '%SUBCONTRACTOR_ADDRESS2%', '%SUBCONTRACTOR_CITY%', '%SUBCONTRACTOR_STATE%', '%SUBCONTRACTOR_POSTAL_CODE%', '%SUBCONTRACTOR_WORK_PHONE%', '%SUBCONTRACTOR_CELL_PHONE%', '%SUBCONTRACTOR_ALT1_PHONE%', '%SUBCONTRACTOR_ALT2_PHONE%', '%SUBCONTRACTOR_EMAIL%', '%START_DATE%', '%END_DATE%', '%WORK_ORDER_LINE_ITEMS%', '%WORK_ORDER_TOTAL%', '%ESTIMATOR_NOTES%', '%SUBCONTRACTOR_NOTES%'] };
	serviceAgreements: KeywordCategories = { category: 'Service Agreement', keywords: ['%SERVICE_AGREEMENT_NOTES%', '%CHANGE_ORDER_AMOUNT%', '%CURRENT_DATE%'] };
	constructor() { }

	getServiceAgreementKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.jobKeywords, this.serviceAgreements];
	}	

	getEstimateKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.estimateKeywords, this.jobKeywords];
	}

	getInvoiceKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.invoiceKeywords, this.jobKeywords];
	}

	getPaymentRequestKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.paymentKeywords];
	}

	getPaymentReceiptKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.paymentKeywords];
	}
	getWorkOrderKeywords(): KeywordCategories[] {
		return [this.companyKeywords, this.customerKeywords, this.jobKeywords, this.workOrderKeywords];
	}
	getMitigationReportKeywords(): KeywordCategories[] {
		return [this.companyKeywords,this.customerKeywords,	this.jobKeywords];
	}
}
